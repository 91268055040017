// Support for older Safari
import 'whatwg-fetch';

import Vue from 'vue/dist/vue.esm';
import * as Sentry from '@sentry/browser';

import Select2 from '../components/select2.vue';
import Datepicker from '../components/datepicker.vue';
import Timepicker from '../components/timepicker.vue';
import OnetPicker from '../components/onet_picker.vue';
import CipPicker from '../components/cip_picker.vue';
import NaicsPicker from '../components/naics_picker.vue';
import OfficePicker from '../components/office_picker.vue';
import RapidsPicker from '../components/rapids_picker.vue';
import JobTitlePicker from '../components/job_title_picker.vue';

const pickerTypes = [
  'datepicker',
  'timepicker',
  'onetpicker',
  'cippicker',
  'naicspicker',
  'rapidspicker',
  'officepicker',
  'jobtitlepicker',
];

function attachPickerComponents(parent) {
  const list = [];

  pickerTypes.forEach((name) => {
    const { length } = parent.querySelectorAll(name);
    if (length > 0) {
      for (let i = 0; i < length; i += 1) {
        list.push(new Vue({ el: name }));
      }
    }
  });

  return list;
}

global.attachPickerComponents = attachPickerComponents;

document.addEventListener('DOMContentLoaded', () => {
  if (process.env.RAILS_ENV === 'production') {
    Sentry.init({
      dsn: 'https://41c76badf8dd42cf9c908ee883619619@sentry.io/1424323',
      integrations: [new Sentry.Integrations.Vue({
        Vue,
        attachProps: true,
      })],
      tracesSampleRate: 0.0, // Disable performance monitoring, errors are still logged.
    });
  }

  Vue.component('Select2', Select2);
  Vue.component('datepicker', Datepicker);
  Vue.component('timepicker', Timepicker);
  Vue.component('onetpicker', OnetPicker);
  Vue.component('cippicker', CipPicker);
  Vue.component('naicspicker', NaicsPicker);
  Vue.component('officepicker', OfficePicker);
  Vue.component('rapidspicker', RapidsPicker);
  Vue.component('jobtitlepicker', JobTitlePicker);

  window.pageComponents = attachPickerComponents(document);
  global.Vue = Vue;

  $(document).on('cocoon:after-insert', (e, insertedItem) => {
    const inputs = insertedItem.find(pickerTypes.join(','));
    $.each(inputs, (_key, value) => {
      const inputId = value.getAttribute('input-id');
      if (inputId !== 'undefined' && inputId != null) {
        // eslint-disable-next-line no-new
        new Vue({ el: `[input-id="${inputId}"]` });
      }
    });
  });

  $('.ui-datepicker').addClass('notranslate');
});
